"use client";

import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import React, { useState } from "react";
import Image115 from "./image 115.png";
import Image116 from "./image 116.png";
import Image117 from "./image 117.png";
import Image118 from "./image 118.png";
import Image119 from "./image 119.png";
import Image120 from "./image 120.png";
import Image122 from "./image 122.png";
import Image123 from "./image 123.png";
import Image57 from "./image 57.png";
import Image56 from "./image 56.png";
import Image59 from "./image 59.png";
import Image60 from "./image 60.png";
import Image61 from "./image 61.png";
import Image62 from "./image 62.png";
import Image63 from "./image 63.png";
import Image77 from "./image 77.png";
import Image99 from "./image 99.png";
import Ulead from "./Ulead.png";
import ContentContainer from "../ContentContainer";
import { CheckOutlined } from "@ant-design/icons";
import ContactFormModal from "../ContactFormModal";
import useDisclosure from "@/hooks/shared/useDisclosure";
interface FeatureItemProps {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  image: StaticImageData;
  alt: string;
  reverse: boolean;
}

const FeaturesDataDetail = {
  callbot_ai: {
    title: "Giải pháp Callbot AI Auto Call",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            <span className="font-bold">Gọi tự động</span> tiếp cận hàng ngàn
            khách hàng chỉ với một cú click chuột
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            <span className="font-bold">Giao tiếp thông minh</span> với công
            nghệ Nhận diện giọng nói, phân biệt giới tính nam nữ, cảm ứng giọng
            nói
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            <span className="font-bold">Xây dựng kịch bản</span> dễ dàng chỉ với
            thao tác kéo thả
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            <span className="font-bold">Báo cáo thông minh</span> theo thời gian
            thực và các đoạn ghi âm được chuyển thành văn bản bởi AI
            speech-to-text
          </div>
        </div>
      </div>
    ),
    image: Image59,
  },
  da_kenh: {
    title: "Giải pháp chăm sóc khách hàng đa kênh",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Quản lý tin nhắn Facebook, Instagram, Zalo, Whatsapp,... chỉ với một
            màn hình
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Không lo bỏ lỡ tin nhắn, bình luận của khách hàng từ nhiều trang nền
            tảng bán hàng
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Liên kết cuộc hội thoại với khách hàng giúp đồng bộ lịch sử tương
            tác với khách hàng.
          </div>
        </div>
      </div>
    ),
    image: Image57,
  },
  tong_dai: {
    title: "Tổng đài ảo ứng dụng AI",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Tổng đài nhận cuộc gọi vào, gọi ra, gọi từ thiết bị máy tính và điện
            thoại
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Cài đặt máy nhánh, định tuyến cuộc gọi</div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Ghi âm cuộc gọi, báo cáo và phân tích cuộc gọi bằng AI</div>
        </div>
      </div>
    ),
    image: Image77,
  },
  crm: {
    title: "CRM - Phần mềm quản lý bán hàng tập trung",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Quản lý khách hàng tập trung và đồng bộ</div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Phân loại theo tập khách hàng giúp quản lý và chăm sóc dễ dàng
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Quản lý thông minh tất cả lịch sử tương tác của khách hàng.</div>
        </div>
      </div>
    ),
    image: Image56,
  },
  nhan_tin: {
    title: "Chiến dịch gửi tin nhắn hàng loạt",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Giao tiếp với hàng ngàn khách hàng qua chiến dịch gửi SMS, ZNS hàng
            loạt
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Thao tác tạo chiến dịch đơn giản, có thể thiết lập chiến dịch riêng
            cho từng tập khách hàng
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Quản lý thông minh tất cả lịch sử tương tác của khách hàng.</div>
        </div>
      </div>
    ),
    image: Image60,
  },
  telesales: {
    title: "Phần mềm quay số Telesales tự động",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Hỗ trợ nhiều hình thức gọi: Munaul Dial, Preview Dial, Progressive
            Dial, Predictive Dial, Hybrid Dial
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Quản lý chiến dịch: giao việc cho nhân viên telesales dễ dàng, báo
            cáo hiệu quả chiến dịch trực quan theo thời gian thực
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Lưu trữ ghi âm cuộc gọi và phân tích ghi âm cuộc gọi bằng AI.
          </div>
        </div>
      </div>
    ),
    image: Image61,
  },
  uflow: {
    title: "Nền tảng tự động hoá UFlow",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Thiết kế flow automation dễ dàng với thao tác kéo, thẻ, nối các điều
            kiện đơn giản, rõ ràng để xây dựng quy trình tự động marketing, bán
            hàng, chăm sóc khách hàng
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Tích hợp các phần mềm bên thứ 3:
            <br />
            • UFlow có sẵn các luồng kết hợp phần mềm bên thứ 3 như Larksuite,
            Pancake <br />• Có thể tích hợp với bất cứ phần mềm mở nào qua
            webhook
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Quản lý thông minh tất cả lịch sử tương tác của khách hàng.</div>
        </div>
      </div>
    ),
    image: Image62,
  },
  ulead: {
    title: "Nền tảng tìm kiếm khách hàng tiềm năng",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Tìm kiếm hàng ngàn khách hàng doanh nghiệp tiềm năng với bộ lọc data
            khách hàng
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Quét số điện thoại khách hàng trên Facebook</div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Chuyển Facebook UID ra số điện thoại và từ số điện thoại sang UID
          </div>
        </div>
      </div>
    ),
    image: Image63,
  },
  email_marketing: {
    title:
      "Giải pháp Email Marketing & Automation hiệu quả nhất cho doanh nghiệp",
    description: (
      <div className="mt-9 flex flex-column gap-y-3 ">
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>
            Tạo email nhanh hơn, hay hơn, đẹp hơn chỉ trong vài cú nhấp chuột
          </div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Gửi email marketing đúng thông điệp, đúng thời điểm</div>
        </div>
        <div className="flex flex-row items-center">
          <CheckOutlined className="text-2xl mr-3" />
          <div>Theo dõi, phân tích và điều chỉnh chiến dịch email của bạn</div>
        </div>
      </div>
    ),
    image: Image99,
  },
};

const features = [
  {
    id: "crm",
    featureTitle: "CRM",
    filePath: Image115,
  },
  {
    id: "tong_dai",
    featureTitle: "Tổng đài",
    filePath: Image116,
  },
  {
    id: "da_kenh",
    featureTitle: "Đa kênh",
    filePath: Image117,
  },
  {
    id: "callbot_ai",
    featureTitle: "Callbot AI",
    filePath: Image118,
  },
  {
    id: "telesales",
    featureTitle: "Telesales",
    filePath: Image119,
  },
  {
    id: "nhan_tin",
    featureTitle: "Nhắn tin",
    filePath: Image120,
  },
  {
    id: "ulead",
    featureTitle: "ULead",
    filePath: Ulead,
  },
  {
    id: "uflow",
    featureTitle: "UFlow",
    filePath: Image122,
  },
  {
    id: "email_marketing",
    featureTitle: "Email Marketing",
    filePath: Image123,
  },
];

const Features = ({type= "ucall"}: {type?: "ucall" | "adahub"}) => {
  const [selected, setSelected] = useState<string>("crm");
  const { open, isOpen, close } = useDisclosure();

  const FeatureItem: React.FC<FeatureItemProps> = ({
    title,
    description,
    buttonText,
    image,
    alt,
  }) => (
    <div className={`row justify-between mt-16`}>
      <div className="col-12 col-md-6">
        <h4 className="font-semibold text-2xl text-adahubSecondary">{title}</h4>
        <div className="mt-3 whitespace-pre-line">{description}</div>
       <div className="flex justify-center md:justify-start">
       <Button
          color="primary"
          onClick={() => {
            open();
          }}
          className="mt-4 mb-4 bg-adahubSecondary rounded-3xl"
          variant="solid">
          Đăng kí tư vấn
        </Button>
       </div>
      </div>
      <div className="col-12 col-md-6 md:max-w-[550px]">
        <Image src={image} alt={alt} />
        <div className="py-2" />
      </div>
    </div>
  );
  return (
    <div className="full-size flex flex-column items-center justify-center mt-9 mb-3">
      <ContentContainer>
        <div className="text-center">
          <div className="text-lg mb-6">
            {type == "ucall" && (<>Khám phá nhiều sản phẩm hơn của UCALL</>)}
            {type == "adahub" && (<>Khám phá nhiều sản phẩm hơn của Adahub</>)}
            
          </div>
          {type == "ucall" && (<> <h3 className="font-semibold text-3xl text-adahubSecondary">
            Giải phóng đội ngũ kinh doanh của bạn
            <br />
            với cụm chức năng của UCALL
          </h3></>)}
          {type == "adahub" && (<> <h3 className="font-semibold text-3xl text-adahubSecondary">
            Giải phóng đội ngũ kinh doanh của bạn
            <br />
            với cụm chức năng của Adahub
          </h3></>)}
         
        </div>
      </ContentContainer>
      <div className="flex flex-row mt-6 w-full items-center justify-center">
        <div className="flex flex-row max-w-[1100px] overflow-x-scroll">
          {features.map((e) => (
            <Card
              isHoverable
              isPressable
              onClick={() => setSelected(e.id)}
              key={`e.${e.id}`}
              shadow="none"
              className="py-4 w-full min-w-[120px]">
              <CardBody className="overflow-visible flex-col justify-center items-center py-2">
                <Image
                  placeholder="blur"
                  alt="Card background"
                  className="object-fit-contain"
                  src={e.filePath}
                  height={50}
                />
              </CardBody>
              <CardHeader className="pb-0 pt-2 px-4 flex-col justify-center items-center h-[64px]">
                <h4 className="font-bold text-sm">{e.featureTitle}</h4>
              </CardHeader>
            </Card>
          ))}
        </div>
      </div>
      <ContentContainer>
        <div className="flex flex-col flex-wrap gap-3">
          <FeatureItem
            key={FeaturesDataDetail[selected]?.id}
            title={FeaturesDataDetail[selected]?.title}
            description={FeaturesDataDetail[selected]?.description}
            buttonText={FeaturesDataDetail[selected]?.buttonText}
            image={FeaturesDataDetail[selected]?.image}
            alt={FeaturesDataDetail[selected]?.alt}
          />
        </div>
      </ContentContainer>
      <ContactFormModal isOpen={isOpen} open={open} close={close} />
    </div>
  );
};

export default Features;
